// React imports
import { useCallback, useEffect } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useEscKey = (callbackFunc: () => void) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        callbackFunc()
      }
    },
    [callbackFunc]
  )

  useEffect(() => {
    document.addEventListener('keydown', (e) => handleKeyDown(e), false)
    return function cleanup() {
      document.removeEventListener('keydown', (e) => handleKeyDown(e), false)
    }
  }, [handleKeyDown])
}

export default useEscKey
